<template>
  <footer class="bg_green">
        <div class="container">
            <div class="d-flex justify-content-between gap-4 gap-md-2 flex-wrap">
                <div class="">
                    <img src="/static/img/logo.svg" alt="logo">
                </div>
                <div class="">
                    <ul class="d-flex flex-column gap-1 list-unstyled m-0">
                        <li><a href="">Обменять криптовалюту</a></li>
                        <li><a href="">Наличные</a></li>
                        <li><a href="">Счета</a></li>
                        <li><a href="">FAQ</a></li>
                        <li><a href="">API интеграция</a></li>
                        <li><a href="">Отзывы</a></li>
                        <li><a href="">Поддержка</a></li>
                    </ul>
                </div>
                <div class="">
                    <ul class="d-flex flex-column gap-1 list-unstyled m-0">
                        <li><a href="">Политика KYC & AML</a></li>
                        <li><a href="">Правила сервиса</a></li>
                        <li><a href="">Политика обработки персональных данных</a></li>
                        <li><a href="">Как купить BTC / Как продать BTC</a></li>
                        <li><a href="">Как купить ETH / Как продать ETH</a></li>
                        <li><a href="">Как купить USDT / Как продать USDT</a></li>
                        <li><a href="">Как купить USDT за наличные</a></li>
                        <li><a href="">Как продать USDT за наличные</a></li>
                    </ul>
                </div>
                    <div class="d-flex flex-column">
                        <div class="d-flex gap-3 align-items-center">
                            <a href=""><img src="/static/img/social/logos_telegram.svg" alt=""></a>
                            <a href=""><img src="/static/img/social/logos_youtube-icon.svg" alt=""></a>
                            <a href=""><img src="/static/img/social/image.png" alt=""></a>
                        </div>
                        <div class="mt-auto"><img src="/static/img/social/Trustpilot_Logo.svg"></div>
                    </div>
            </div>
        </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style scoped>
  footer {
    padding: 37px 0 57px;
  }
</style>