<template>
  <div class="home">
    <div class="container">
      <h1 :class="currency_from && currency_to ? 'text-light' : 'opacity-0'" class="mb-4 h2 text-center">Обменять {{ currency_from }} на {{ currency_to }}</h1>
    </div>
    <Form :paymentMethods="paymentMethods" @changeMethod="changeMethod" @summSent="handleDataFromChild"/>
    <Benefits v-if="!currency_to || !currency_from" />
    <Proposal />
    <Numbers />
    <Bottom :currency_to="currency_to" :currency_from="currency_from" :summ="summ"/>
    <Confmodal/>
  </div>
</template>

<script>
import Form from '@/components/sections/Form.vue'
import Benefits from '@/components/sections/Benefits.vue'
import Proposal from '@/components/sections/Proposal.vue'
import Numbers from '@/components/sections/Numbers.vue'
import Bottom from '@/components/sections/Bottom.vue'
import Confmodal from '@/components/Confirmation-modal.vue'

export default {
  name: 'HomeView',
  components: {
    Form,
    Benefits,
    Proposal,
    Numbers,
    Bottom,
    Confmodal,
  },
  data: () => ({
    currency_from: 0,
    currency_to: 0,
    summ: 0,
    paymentMethods: [
            {
                id: 12,
                text: 'Тинькофф',
                currency: 'RUB',
                link: 'tinkoff-rub'
            },
            {
                id: 14,
                text: 'Сбербанк',
                currency: 'RUB',
                link: 'sberbank-rub'
            },
            {
                id: 14,
                text: 'Казахский банк',
                currency: 'KZT',
                link: 'kzbank-kzt'
            },
            {
                id: 15,
                text: 'Американский банк',
                currency: 'USD',
                link: 'american-usd'
            },
            {
                id: 16,
                text: 'Perfect money - EUR',
                currency: 'EUR',
                link: 'perfect-money-eur'
            },
            {
                id: 17,
                text: 'USDT - TRC20',
                currency: 'USDT',
                link: 'usdt-trc-20'
            },
            {
                id: 18,
                text: 'тест безнал',
                currency: 'EU – Безнал',
                link: 'eu-cashless'
            },
            {
                id: 18,
                text: 'тест безнал',
                currency: 'РФ – Безнал',
                link: 'rf-cashless'
            },
            {
                id: 19,
                text: 'Сбербанк1',
                currency: 'RUB',
                link: 'sberbank-rub1'
            },
            {"id": 21, "text": "Сбербанк2", "currency": "RUB", "link": "sberbank-rub2"},
            {"id": 22, "text": "Сбербанк3", "currency": "RUB", "link": "sberbank-rub3"},
            {"id": 23, "text": "Сбербанк4", "currency": "RUB", "link": "sberbank-rub4"},
            {"id": 24, "text": "Сбербанк5", "currency": "RUB", "link": "sberbank-rub5"},
            {"id": 25, "text": "Сбербанк6", "currency": "RUB", "link": "sberbank-rub6"},
            {"id": 26, "text": "Сбербанк7", "currency": "RUB", "link": "sberbank-rub7"},
            {"id": 27, "text": "Сбербанк8", "currency": "RUB", "link": "sberbank-rub8"},
            {"id": 28, "text": "Сбербанк9", "currency": "RUB", "link": "sberbank-rub9"},
            {"id": 29, "text": "Сбербанк10", "currency": "RUB", "link": "sberbank-rub10"},
            {"id": 30, "text": "Сбербанк11", "currency": "RUB", "link": "sberbank-rub11"},
            {"id": 31, "text": "Сбербанк12", "currency": "RUB", "link": "sberbank-rub12"},
            {"id": 32, "text": "Сбербанк13", "currency": "RUB", "link": "sberbank-rub13"},
            {"id": 33, "text": "Сбербанк14", "currency": "RUB", "link": "sberbank-rub14"},
            {"id": 34, "text": "Сбербанк15", "currency": "RUB", "link": "sberbank-rub15"},
            {"id": 35, "text": "Сбербанк16", "currency": "RUB", "link": "sberbank-rub16"},
            {"id": 36, "text": "Сбербанк17", "currency": "RUB", "link": "sberbank-rub17"},
            {"id": 37, "text": "Сбербанк18", "currency": "RUB", "link": "sberbank-rub18"},
            {"id": 38, "text": "Сбербанк19", "currency": "RUB", "link": "sberbank-rub19"},
            {"id": 39, "text": "Сбербанк20", "currency": "RUB", "link": "sberbank-rub20"},
            {"id": 40, "text": "Сбербанк21", "currency": "RUB", "link": "sberbank-rub21"}
      ]  
    }),
    methods: {
      changeMethod (data) {
        console.log(data);
        this.currency_from = data.currency_from;
        this.currency_to = data.currency_to;
        document.title = 'Обменять ' + (this.currency_from && this.currency_from !== 0 ? this.currency_from + ' на ' : 'валюту') + (this.currency_to && this.currency_to !== 0 ? this.currency_to : '') + ' по лучшему курсу - Valutix'; // Установка Title
        this.updateMetaDescription('Ищете возможность обменять '+this.currency_from+' на '+this.currency_to+''); // Установка Description
      },
      updateMetaDescription(content) {
        let metaTag = document.querySelector('meta[name="description"]');
        if (!metaTag) {
          metaTag = document.createElement('meta');
          metaTag.setAttribute('name', 'description');
          document.head.appendChild(metaTag);
        }
        metaTag.setAttribute('content', content);
      },
      handleDataFromChild(data) {
        this.summ = data;
      }

    },
    mounted() {
      const currentUrl = window.location.pathname;
      console.log(currentUrl);
      // Избавляемся от начального слеша, если он есть
      const trimmedPath = currentUrl.startsWith('/') ? currentUrl.slice(1) : currentUrl;
      // Разделение пути на части по разделителю '_'
      const parts = trimmedPath.split('_');
      if (parts.length === 2) {
        // Запись значений в переменные
        const [currencyFrom, currencyTo] = parts;
        // Вывод значений для проверки
        console.log(`Currency From: ${currencyFrom}, Currency To: ${currencyTo}`);
        this.currency_from = this.paymentMethods.find(m=> m.link == currencyFrom).currency;
        this.currency_to = this.paymentMethods.find(m=> m.link == currencyTo).currency;
      } else {
        console.error('URL не содержит двух частей, разделённых символом "_"');
      }
      document.title = 'Обменять ' + (this.currency_from && this.currency_from !== 0 ? this.currency_from + ' на ' : 'валюту') + (this.currency_to && this.currency_to !== 0 ? this.currency_to : '') + ' по лучшему курсу - Valutix'; // Установка Title
      this.updateMetaDescription('Ищете возможность обменять '+this.currency_from+' на '+this.currency_to+''); // Установка Description
    },

}
</script>
