<template>
    <section class="bottom_section">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-7 z-1">
                    <h2>Криптобанк Valutix <br> <span class="text-green">Обрети финансовую свободу</span></h2>
                    <div class="text mb_custom">
                        <p>{{ generatedVariations(action) }} {{ generatedVariations(exchangeOptions) }} <span v-if="currency_to">{{ currency_to }}</span> <span v-if="currency_from">за {{currency_from}}</span> {{ generatedVariations(transactionMethod) }} <span v-if="summ">{{ generatedVariations(transactionType) }} {{ summ }}</span>? В Криптобанке Valutix вы можете сделать это {{ generatedVariations(cryptobankAttributes) }} и с самыми {{ generatedVariations(commissionAttributes) }} комиссиями! Регистрируйтесь в Криптобанке Valutix и вы сможете {{ generatedVariations(actionOptions) }} <span v-if="currency_from">{{currency_from}}</span><span v-if="!currency_from">желаемую валюту</span>.</p>

                        <p>Криптобанк Valutix - это {{ generatedVariations(cryptobankDescription)  }} партнёр в {{ generatedVariations(locationDescription)  }} криптовалют.</p>
                        
                        <p>{{ generatedVariations(action2)  }}, <span v-if="currency_from">{{currency_from}}</span> на {{ generatedVariations(platformDescription) }}, вы становитесь частью быстрорастущего и перспективного {{ generatedVariations(marketDescription) }} криптовалюты. Мы {{ generatedVariations(efforts) }} сделать процесс {{ generatedVariations(transactionProcessDescription) }} максимально {{ generatedVariations(transactionSpeed) }} и {{ generatedVariations(transactionConvenience) }} для наших {{ generatedVariations(userDescription) }}, предоставляя им возможность контролировать свои {{ generatedVariations(investmentControlDescription) }} и следить за их динамикой в режиме реального времени.</p>
                        
                        <p>Наш Криптобанк Valutix предлагает простой и безопасный способ покупки <span v-if="!currency_to">криптовалюты</span> <span v-if="currency_to">{{ currency_to }}</span>. Это обеспечивает минимальные комиссии и высокую скорость транзакций. Приобретая <span v-if="!currency_to">валюту</span> <span v-if="currency_to">{{ currency_to }}</span> на нашей платформе, вы делаете выбор в пользу надежного и перспективного инвестиционного инструмента.</p>
                    </div>
                    <a href="https://staging.valutix.io/registration" class="myButton">Регистрация</a>
                </div>
                <div class="col-12 col-lg-5 d-flex justify-content-end d-lg-block">
                    <picture>
                        <source srcset="/static/img/bottom-mobile-img.png" media="(max-width: 992px)">
                        <img src="/static/img/bottom-img.png" class="bottom_img mt_negative z-0">
                    </picture>
                </div>
                
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: ['currency_to', 'currency_from', 'summ'],
    data: () => ({
        action: ["Желаете", "Хотите", "Собираетесь"],
        exchangeOptions: ['совершить обмен', 'обменять', 'провести мгновенный обмен'],
        transactionMethod: ["банковской карточкой", "переводом", "из кошелька", "наличными", "по ссылке"],
        transactionType: ["в сумме", "стомостью"],
        cryptobankAttributes: ["быстро", "мгновенно", "оперативно", "просто", "безопасно", "без посредников", "за пару минут", "без регистрации"],
        commissionAttributes: ["низкими", "маленькими", "минимальными"],
        actionOptions: ['купить', 'продать', 'обменять'],
        cryptobankDescription: ["надёжный", "стабильный", "лучший"],
        locationDescription: ["мире", "индустрии"],
        action2: ["Меняя", "Обменивая", "Совершая обмен"],
        platformDescription: ["нашей платформе", "нашем сервисе", "Valutix"],
        marketDescription: ["рынка", "мира"],
        efforts: ["стремимся", "стараемся", "работаем над тем, чтобы"],
        transactionProcessDescription: ["покупки", "обмена"],
        transactionSpeed: ["простым", "мгновенным", "оперативным"],
        transactionConvenience: ["удобным", "безопасным"],
        userDescription: ["пользователей", "клиентов", "юзеров"],
        investmentControlDescription: ["инвестиции", "активы"],
        cryptocurrencyBenefits: ["минимальные комиссии", "высокая скорость транзакций"],
        cryptocurrencyAttributes: ["надежного", "перспективного"]
    }),
    methods: {
        generatedVariations(arr) {
           return arr[(Math.floor(Math.random() * arr.length))]
        }
    }
    }
</script>

<style scoped>
    .bottom_section {
        padding: 120px 0;
    }
    .mb_custom {
        margin-bottom: 30px;
    }
    /* .bottom_img {
        position: absolute;
        max-height: 762px;
        max-width: 745px;
    } */
    @media (max-width: 500px) {
        .mt_negative {
            margin-top: -50px;
        }

        .bottom_section {
            padding: 50px 26px;
        }

        /* .bottom_img {
            position: unset;
            max-height: unset;
            max-width: unset;
        } */
    }

</style>