<template>
    <nav class="navbar navbar-expand-lg" data-bs-theme="dark">
        <div class="container">
          <router-link exact class="navbar-brand"  to="/"><img src="/static/img/logo.svg" alt="logo"></router-link>
          <button class="navbar-toggler border-0 shadow-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="rounded-start offcanvas offcanvas-end text-bg-dark" tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
            <div class="offcanvas-header">
              <router-link exact class="navbar-brand"  to="/"><img src="/static/img/mobile_logo.png" alt="logo"></router-link>
              <button type="button" class="btn-close btn-close-white shadow-none" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body d-flex flex-column flex-lg-row">
              <ul class="navbar-nav flex-grow-1 pe-3 justify-content-lg-center">
                <li class="nav-item">
                  <a  target="_blank" class="nav-link" href="https://staging.valutix.io">Криптобанк</a>
                </li>
                <li class="nav-item">
                  <router-link exact class="nav-link"  to="/">Мгновенный обмен</router-link>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Возможности
                  </a>
                  <ul class="dropdown-menu dropdown-menu-dark border-0">
                    <li>
                      <a target="_blank" href="https://staging.valutix.io/cash" class="dropdown-item">Наличные</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://staging.valutix.io/api" class="dropdown-item">API интеграция</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://staging.valutix.io/invoices" class="dropdown-item">Счета</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://staging.valutix.io/paiment-link" class="dropdown-item">Оплата по ссылке</a>
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a  target="_blank" class="nav-link" href="https://faq.valutix.io">FAQ</a>
                </li>
                <li class="nav-item">
                  <a target="_blank" href="https://staging.valutix.io/contact" class="nav-link">Контакты</a>
                </li>
              </ul>
              <div class="d-flex align-items-center gap-3 flex-wrap flex-lg-nowrap mt-auto mt-lg-0">
                <a target="_blank" href="https://staging.valutix.io/login" class="order-2 order-lg-1 border_lightgreen rounded px-3 py-2 text-white text-decoration-none">Вход</a>
                <a target="_blank" href="https://staging.valutix.io/registration" class="order-3 order-lg-2 border_lightgreen rounded px-3 py-2 text-white text-decoration-none">Регистрация</a>
                <a target="_blank" href="https://t.me/valutix" class="telegram order-1 order-lg-3 mb-5 mb-lg-0"><img src="/static/img/logos_telegram.svg" alt="telegram"></a>
              </div>
            </div>
          </div>
        </div>
      </nav>
</template>

<script>
export default {

}
</script>

<style>
    .router-link-active {
        color: #4AA485 !important;
    }
.dropdown-menu.show {
    background: #35634F;
}
.nav-link {
    color: #fff;
    text-wrap: wrap;
    word-break: break-all;
}
.navbar-nav .nav-link.active {
    color: #4AA485;
}
.text-bg-dark {
    background-color: #35634F !important;
}
.border_lightgreen  {
    border: 1px solid #4AA485;
}

@media(max-width: 768px){
    .telegram {
        margin-right: 100%;
    }
}

</style>